<template>
  <div>
    <jf-import :label='label' :next="next" api="/platform-config/incident/import/platformEventLibraryBatchImport" download="事件库批量导入模板.xls" href="https://dianzanjifenzhi-projectfile.oss-cn-hangzhou.aliyuncs.com/template/%E5%B9%B3%E5%8F%B0%E7%AB%AF-%E4%BA%8B%E4%BB%B6%E5%BA%93%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5.xls"></jf-import>
  </div>
</template>
<script>

export default {
  data() {
    return {
      label: [
        {
          label: "事件类型",
          prop: "incidentTypeName",
        },
        {
          label: "事件名称",
          prop: "incidentName",
        },
        {
          label: "事件说明",
          prop: "incidentDesc",
        },
        {
          label: "A分最小值",
          prop: "amin",
        },
        {
          label: "A分最大值",
          prop: "amax",
        },
        {
          label: "B分最小值",
          prop: "bmin",
        },
        {
          label: "B分最大值",
          prop: "bmax",
        },
        {
          label: "产值最小值",
          prop: "outputMin",
        },
        {
          label: "产值最大值",
          prop: "outputMax",
        },
        {
          label: "状态",
          prop: "incidentStatus",
        },
        {
          label: "奖票事件",
          prop: "isLottery",
        },
        {
          label: "计件事件",
          prop: "isPiecework",
        },
       
      ],
    };
  },
  created() {},
  methods: {
   
    clearError(row, index) {
      var cell = row.getElementsByClassName("cell")[index];
      cell.style.border = "none";
      cell.title = "";
     
    },
    addError(row, index, msg) {
      var cell = row.getElementsByClassName("cell")[index];
      cell.style.border = "1px solid red";
      cell.title = msg;
    },

    next(arr, ele) {
      console.log(arr, ele);
      /* 验证数据长度 */
      if (!arr.length) {
        this.$message.warning("最少需要一条数据！");
        return false;
      }
      if (arr.length > 500) {
        this.$message.warning("最多500条数据！");
        return false;
      }
      /* 验证数据格式 */
      let flag = true;
      arr.map((item, index) => {
        var row = ele.getElementsByTagName("tr")[index + 1];
        /* 事件类型必填项 */
        this.clearError(row, 1);
        if (!item.incidentTypeName) {
          this.addError(row, 1, "事件类型不能为空");
          flag = false;
        }
        /* 事件名称必填项 */
        this.clearError(row, 2);
        if (!item.incidentName) {
          this.addError(row, 2, "事件名称不能为空");
          flag = false;
        }
        /* A分最小值必填项 */
        this.clearError(row, 4);
        if (!item.amin) {
          this.addError(row, 4, "A分最小值不能为空");
          flag = false;
        }
        /* A分最大值必填项 */
        this.clearError(row, 5);
        if (!item.amax) {
          this.addError(row, 5, "A分最大值不能为空");
          flag = false;
        }
        /* B分最小值必填项 */
        this.clearError(row, 6);
        if (!item.bmin) {
          this.addError(row, 6, "B分最小值不能为空");
          flag = false;
        }
        /* B分最大值必填项 */
        this.clearError(row, 7);
        if (!item.bmax) {
          this.addError(row, 7, "B分最大值不能为空");
          flag = false;
        }
        /* 产值最小值必填项 */
        this.clearError(row, 8);
        if (!item.outputMin) {
          this.addError(row, 8, "产值最小值不能为空");
          flag = false;
        }
        /* 产值最大值必填项 */
        this.clearError(row, 9);
        if (!item.outputMin) {
          this.addError(row, 9, "产值最大值不能为空");
          flag = false;
        }
        /* 状态必填项 */
        this.clearError(row, 10);
        if (!item.incidentStatus) {
          this.addError(row, 10, "状态不能为空");
          flag = false;
        }
        if (item.incidentStatus && item.incidentStatus!=='启用'  && item.incidentStatus!=='停用') {
          this.addError(row, 10, "状态只能填写：启用、停用");
          flag = false;
        }
        /*  */
      });
      return flag;
    },
  },
};
</script>

<style lang='scss' scoped>
</style>
